import { createApp } from 'vue'
import App from './App.vue'

import mitt from 'mitt';
const emitter = mitt();

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'

const app = createApp(App);
app.provide('emitter', emitter);
app.mount('#app')
