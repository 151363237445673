<template>
  <div class="container">
    <h1 class="text-center"><strong>Crawl website truyenhdt.com</strong></h1>
    <div class="text-start">
      <div>
        <div class="story-crawl-group" v-for="(item, key) in items" :key="key">
          <button v-if="key > 0" class="story-crawl-group-remove-item" type="button" @click="removeItem(key)"><img src="./assets/remove-icon.png"></button>
          <div>
            <label for="">Vui lòng nhập url của bộ truyện</label>
            <input class="form-control" :class="validateUrlFailed[key] ? 'is-invalid' : null" id="" type="url" v-model="item.url">
            <div id="url" class="invalid-feedback">
              {{ validateMessage[key] }}
            </div>
          </div>
          <div>
            <label for="">Crawl từ chương: </label>
            <input class="form-control" :class="validateFromChapterFailed[key] ? 'is-invalid' : null" id="" type="number" v-model="item.fromChapter">
            <div id="from_chapter" class="invalid-feedback">
              {{ validateFromChapterMessage[key] }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 text-end">
        <button type="button" @click="addItem" class="btn btn-dark p-5 pt-1 pb-1" style="margin-right: 10px;">Thêm truyện</button>
        <button type="button" @click="submit" class="btn btn-primary p-5 pt-1 pb-1">Start</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data() {
    return {
      validateMessage: [
        '',
      ],
      validateUrlFailed: [
        false
      ],
      validateFromChapterMessage: [
        ''
      ],
      validateFromChapterFailed: [
        false
      ],
      items: [{
        url: null,
        fromChapter: 1,
      }],
    }
  },
  methods: {
    removeItem(index) {
      this.items.splice(index);
    },

    addItem() {
      this.items.push({
        url: null,
        fromChapter: 1,
      });
    },

    async submit() {
      this.resetDataValidator();
      if (!this.validateUrlField() || !this.validateFromChapterField()) {
        return;
      }

      const params = this.items.map(function (item) {
        return {
          url: item.url,
          fromChapter: item.fromChapter
        }
      });

      await window.electron.handleCrawl(params);
    },

    validateUrlField() {
      const regex = /^(https:.*truyenhdt.com.*truyen)/;
      let key = 0;
      for (const item of this.items) {
        if (item.url === null || item.url.length === 0) {
          this.validateUrlFailed[key] = true;
          this.validateMessage[key] = 'Điền url của bộ truyện';

          return false;
        }

        if (!regex.exec(item.url)) {
          this.validateUrlFailed[key] = true;
          this.validateMessage[key] = 'Url phải bắt đầu bằng https://truyenhdt.com/truyen/';

          return false;
        }

        key++;
      }

      return true;
    },

    validateFromChapterField() {
      let key = 0;
      for (const item of this.items) {
        if (item.fromChapter <= 0) {
          this.validateFromChapterFailed[key] = true;
          this.validateFromChapterMessage[key] = 'Số chương phải lớn hơn 0';

          return false;
        }
        key++;
      }

      return true;
    },

    resetDataValidator() {
      this.validateUrlFailed = [
        false
      ];
      this.validateMessage = [
        ''
      ];
      this.validateFromChapterFailed = [
        false
      ];
      this.validateFromChapterMessage = [
        ''
      ];
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.story-crawl-group {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 20px;
  margin-bottom: 15px;
  position: relative;
}

.story-crawl-group:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.story-crawl-group-remove-item {
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
}

.story-crawl-group-remove-item img {
  max-width: 100%;
}
</style>
